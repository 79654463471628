import { useLoadings } from "api/wms/hooks";
import { InfoLabel } from "components/common/infoLabel/InfoLabel";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { CommonError } from "components/utils";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const LoadingLink = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const search = queryString.stringify({
    source: "ROUTE",
    sourceId: panelId,
  });
  const { data: loadings, error, isLoading } = useLoadings(search);

  if (error)
    return (
      <InfoLabel title="Załadunek">
        <CommonError status={error._httpStatus_} />
      </InfoLabel>
    );

  if (isLoading)
    return (
      <InfoLabel title="Załadunek">
        <Spinner size={20} />
      </InfoLabel>
    );

  if (!loadings || !Boolean(loadings.length))
    return (
      <InfoLabel title="Załadunek">
        <EmptyValue />
      </InfoLabel>
    );

  return (
    <InfoLabel title="Załadunek">
      <Link
        fontSize="14"
        fontWeight="700"
        key={loadings[0].id}
        to={`/wms/loadings/list/all?panelId=${loadings[0].id}`}
      >
        {loadings[0].signature}
      </Link>
    </InfoLabel>
  );
};
