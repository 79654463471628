import { createLoading, createPicking } from "api/orders/calls";
import { CreatePickingOrLoading } from "api/orders/models";
import { wmsKeys } from "api/wms/keys";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { DrawerSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey, queryString } from "utilities";

export const CreatePickingAndLoadingSection = ({ id, signature, kind }: CreatePickingOrLoading) => {
  const { query } = useQuery();
  const { panelId } = query;
  const search = queryString.stringify({
    source: "ROUTE",
    sourceId: panelId,
  });

  const createLoadingMutation = useMutation(createLoading, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsKeys.loading.list(search));
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono załadunek",
      });
    },
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));

  const createPickingMutation = useMutation(createPicking, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(wmsKeys.picking.list(search));
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono picking",
      });
    },
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));

  return (
    <DrawerSection className="pb-3" title=" ">
      <div className="d-flex align-items-center justify-content-between ">
        <Button
          className="text-uppercase"
          isLoading={createPickingMutation.isLoading}
          onClick={() =>
            createPickingMutation.mutate({
              id,
              signature,
              kind,
            })
          }
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Utwórz picking
        </Button>
        <Button
          className="text-uppercase"
          isLoading={createLoadingMutation.isLoading}
          onClick={() =>
            createLoadingMutation.mutate({
              id,
              signature,
              kind,
            })
          }
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Utwórz załadunek
        </Button>
      </div>
    </DrawerSection>
  );
};
